import { Button } from "./Button";
import Picker from "@emoji-mart/react";
import { Emoji } from "./Emoji";

export const CustomEmojis = [
  {
    id: "pizza-ninja-avatars",
    name: "Pizza Ninja Avatars",
    keywords: ["Pizza Ninja Avatars"],
    skins: [{ src: "/assets/images/ninjas-animation.gif" }],
  },
  {
    id: "pizza-ninja",
    name: "Pizza Ninja",
    keywords: ["Pizza Ninja"],
    skins: [{ src: "/assets/images/emojis/pizza_ninjas.webp" }],
  },
  {
    id: "pizza-ninja-2",
    name: "Pizza-ninja-2",
    keywords: ["Pizza"],
    skins: [{ src: "/assets/images/emojis/pizza.jpeg" }],
  },
  {
    id: "ninjalerts",
    name: "Ninjalerts",
    keywords: ["Ninjalerts"],
    skins: [{ src: "/assets/images/emojis/ninjalerts.png" }],
  },
  {
    id: "ordinals",
    name: "Ordinals",
    keywords: ["Ordinals"],
    skins: [{ src: "/assets/images/emojis/ordinals.png" }],
  },
  {
    id: "bitcoin",
    name: "Bitcoin",
    keywords: ["Bitcoin"],
    skins: [{ src: "/assets/images/emojis/bitcoin.png" }],
  },
  {
    id: "ethereum",
    name: "Ethereum",
    keywords: ["Ethereum"],
    skins: [{ src: "/assets/images/emojis/ethereum.png" }],
  },
];

export const EmojiPicker: React.FC<{
  emojiId: string | null;
  onEmojiPicked: (imageId: string | null) => void;
}> = ({ emojiId, onEmojiPicked }) => {
  if (emojiId)
    return (
      <Button
        variant={"transparent"}
        size={"icon"}
        className="p-3.5 border  border-secondary/20 bg-primary-background rounded-xl"
        onClick={() => onEmojiPicked(null)}
      >
        <Emoji emojiId={emojiId} />
      </Button>
    );

  return (
    <div className="p-1 border  border-secondary/20 bg-primary-background rounded-xl inline-block">
      <Picker
        onEmojiSelect={(e: any) => {
          onEmojiPicked(e.id);
        }}
        categories={[
          "custom",
          "frequent",
          "people",
          "nature",
          "foods",
          "activity",
          "places",
          "objects",
          "symbols",
          "flags",
        ]}
        theme="dark"
      />
    </div>
  );
};
